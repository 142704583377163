import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const HeroImageWrapper = styled.div`
  width: 100%;
`

function HeroImage(props) {
  const { fluid, alt } = props
  return (
    <HeroImageWrapper>
      <Img fluid={fluid} alt={alt} />
    </HeroImageWrapper>
  )
}

export default HeroImage
