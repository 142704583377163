import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroImage from "../components/hero-image"

const Wrapper = styled.div`
  margin: 96px auto 0;

  h2 {
    margin-bottom: 1rem;
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 2.2rem;
  }

  p {
    font-size: 0.9rem;
    font-weight: 300;
  }

  a {
    text-decoration: none;
    color: brown;
  }
`

const Margins = styled.div`
  margin: 0 auto;
  max-width: 1060px;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    margin: 0 32px;
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: stretch;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.xl}) {
    flex-direction: ${props => props.direction || "row"};
  }
`

const TextWrapper = styled.div`
  width: 50%;
  padding-right: 100px;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: calc(100% - 16px);
    padding: 8px;
    text-align: center;
  }
`

const ImageWrapper = styled.div`
  width: 50vw;
  max-width: ${props => `${props.maxWidth}px` || "auto"};
  flex-shrink: 0;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.xl}) {
    width: calc(100% - 16px);
    padding-top: 20px 8px;
  }
`

const TitleBlock = styled.div`
  margin-right: 60px;
  padding-bottom: 10px;

  h1 {
    margin-bottom: 48px;
    text-decoration: underline;
    font-weight: 800;
    font-size: 3.5rem;
    text-transform: uppercase;
    text-align: left;
  }

  p {
    line-height: 1.5rem;
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    margin-left: 0;
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.small}) {
    margin: 63px 0;
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.small}) {
    h1 {
      font-size: 2.2rem;
      word-break: break-word;
    }
  }
`

const Span = styled.div`
  width: 100%;
`

const ProductDescriptionBlock = styled.div`
  margin-bottom: 20px;
  padding-left: calc(50% - 530px);

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    padding: 16px;
  }
`

const LightBlueSpan = styled(Span)`
  background: ${({ theme }) => theme.palette.lightCol};
  padding: ${props => (props.pageBreak ? "16px" : "48px")};
  text-align: center;
  margin: 16px 0;

  p {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.8rem;
    color: ${({ theme }) => theme.palette.lightText};
  }
`

const ImageSpan = styled.div`
  width: 100%;
  display: flex;
  margin: 20px 8px;
  flex-wrap: wrap;
`

const ImageSpanImageWrapper = styled.div`
  width: calc(25% - 20px);
  margin: 8px;
  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: calc(50% - 20px);
  }
`

const Infobox = styled.div`
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.mainBlue};
  margin-bottom: 25px;
  padding: 30px;
  max-width: 570px;

  h3 {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.8rem;
    color: #000;
  }
  ul {
    list-style-type: none;
    font-size: 0.9rem;
    width: 50%;
  }

  ul li::before {
    content: "☑";
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    margin: 25px auto;
  }
`

const InfoboxWrapper = styled.div`
  width: 50%;
  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: 100%;
    padding: 20px;
  }
`

const OuterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const InfoboxFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  ul:first-child {
    padding-left: 0;
  }
`

const UpperBoxWrapper = styled.div`
  display: flex;
  width: 100%;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    display: initial;
  }
`

const LowerBoxWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const TextBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 96px;
  width: 50%;
  flex: 1 1 0%;

  p {
    padding: 20px 0;
    flex: 1 1 0;
  }

  a {
    display: block;
    color: rgb(255, 255, 255);
    background-color: ${({ theme }) => theme.palette.mainBlue};
    width: fit-content;
    cursor: pointer;
    font-weight: 600;
    line-height: 1em;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    padding: 5px;

    :hover {
      filter: drop-shadow(rgba(0, 0, 0, 0.5) 2px 2px 5px);
    }
  }
`

const Box = styled.div`
  display: flex;
  width: calc(50% - 16px);
  margin: 8px;
  padding: 16px;
  background-color: ${({ theme }) => theme.palette.lightGrey};

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: calc(100% - 16px);
  }
`

const LowerBox = styled.div`
  display: flex;
  width: calc(100% - 16px);
  margin: 8px;
  padding: 16px;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.palette.lightGrey};

  .gatsby-image-wrapper {
    width: 239px;
    height: 100%;
    object-fit: contain !important;
    object-position: center center !important;

    @media only screen and (max-width: ${props =>
        props.theme.breakpoints.large}) {
      width: calc(100% - 16px);
    }
  }
`

const Title = styled.h3`
  font-size: 20px;
  font-weight: bold;
`

const BoxImageWrapper = styled.div`
  width: 50%;
  margin: 0px 0px 0px 16px;
`

const BottomBoxImageWrapper = styled.div`
  width: 50%;
  margin: 0px 0px 0px 16px;
  display: flex;
  justify-content: center;
`

const BoxComponent = styled.div`
  padding-bottom: 20px;
`

const SingleProductPage = () => (
  <StaticQuery
    query={graphql`
      {
        drehtorantrieb: file(name: { eq: "swingx01" }) {
          name
          childImageSharp {
            fluid(maxWidth: 1700, quality: 100) {
              src
              aspectRatio
            }
          }
        }
        gridimage1: file(name: { eq: "levo3000" }) {
          name
          childImageSharp {
            fluid {
              src
              aspectRatio
            }
          }
        }
        gridimage2: file(name: { eq: "levo4000" }) {
          name
          childImageSharp {
            fluid {
              src
              aspectRatio
            }
          }
        }
        allImageSharp(
          filter: {
            fluid: {
              originalName: {
                regex: "/DSC_2280|DSC_2313|HIG_7398|Normstahl_IT0005/"
              }
            }
          }
        ) {
          edges {
            node {
              fluid(maxWidth: 350, maxHeight: 350, cropFocus: CENTER) {
                originalName
                src
                aspectRatio
              }
            }
          }
        }
      }
    `}
    render={props => (
      <Layout>
      <Helmet>
        <link rel="canonical" href="https://normstahl.com/de-de/torantriebe/hoftorantriebe/drehtorantriebe" />
      </Helmet>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <Wrapper>
            <ProductDescriptionBlock>
              <Flex direction={"column"}>
                <TitleBlock>
                  <h1>DREHTOR<wbr />ANTRIEBE</h1>
                  <p>
                  Ein Hoftorantrieb aus unserem Programm zeichnet sich durch
                  robuste Technik, hervorragende Materialqualität und
                  elegantes Design aus. Er ist servicefreundlich,
                  wartungsarm und sparsam im Energieverbrauch. Sie sparen
                  also nicht nur Muskelkraft, sondern auch Kosten. Der
                  Drehtorantrieb Ihrer Wahl ist mit einem 24V-Motor
                  ausgestattet, der leise und zuverlässig arbeitet. Das
                  ganze System lässt sich wegen seines hohen
                  Vorfertigungsgrades leicht installieren.
                  <br />
                  </p>
                </TitleBlock>
                <ImageWrapper>
                <HeroImage
                  fluid={props.drehtorantrieb.childImageSharp.fluid}
                  alt={"Drehtorantrieb"}
                />
                </ImageWrapper>
              </Flex>
            </ProductDescriptionBlock>

          <LightBlueSpan>
            <Margins>
              <p>
                Unsere Drehtorantriebe übernehmen die Arbeit für Sie
                <br />
                Unsere Drehtorantriebe sind die perfekte Einheit von Design und
                Leistung. Der ideale Antrieb für Ihr Tor.
              </p>
            </Margins>
          </LightBlueSpan>

          <BoxComponent>
            <Margins>
              <LowerBoxWrapper>
                <LowerBox>
                  <TextBlock>
                    <Title> Technologisch überzeugend</Title>
                    <p>
                      Nutzen Sie an
                      Ihrem bestehenden Tor bislang noch keinen Hoftorantrieb,
                      so können Sie diesen unkompliziert nachrüsten lassen. Wir
                      beraten Sie gerne.
                    </p>
                      <Title> Bewegt bis zu einer halben Tonne</Title>
                      <p>
                        Ein Normstahl Drehtorantrieb ist wegen der Güte seines
                        Materials äußerst langlebig und wartungsarm. Was Ihnen
                        selbst Mühe bereitet, leistet dieses Produkt Tag und Nacht
                        bei jedem Wetter auf Ihren Wunsch. Jetzt werden Sie sich
                        fragen, ob auch Ihr spezielles Hoftor mit unserer
                        Technologie bewegt werden kann. Nun, unsere Hoftorantriebe
                        sind in zwei Produktlinien erhältlich, die Tore bis zu
                        fünf Meter Flügelbreite und 500 Kilogramm Gewicht
                        meistern. Da ist Ihres ganz sicher kein Problem!
                      </p>
                      <a href="https://normstahl.com/de-de/torantriebe/hoftorantriebe/drehtorantriebe" target="_blank" rel="noopener noreferrer">
                        Mehr erfahren
                      </a>
                  </TextBlock>
                </LowerBox>
              </LowerBoxWrapper>

              <UpperBoxWrapper>
                <Box>
                  <TextBlock>
                    <Title>Schwingtorantrieb LEVO 3000</Title>
                    <p>Für Tore bis 150 kg und 3.000 mm Breite.</p>
                    <a href="https://normstahl.com/de-de/torantriebe/hoftorantriebe/drehtorantriebe" target="_blank" rel="noopener noreferrer">
                      Mehr erfahren
                    </a>
                  </TextBlock>
                  <BoxImageWrapper>
                    <Img
                      fluid={props.gridimage1.childImageSharp.fluid}
                      alt="Schwingtorantrieb LEVO"
                    />
                  </BoxImageWrapper>
                </Box>
                <Box>
                  <TextBlock>
                    <Title>Schwingtorantrieb LEVO 4000</Title>
                    <p>Für Tore bis 500 kg und 5.000 mm Breite.</p>
                    <a href="https://normstahl.com/de-de/torantriebe/hoftorantriebe/drehtorantriebe" target="_blank" rel="noopener noreferrer">
                      Mehr erfahren
                    </a>
                  </TextBlock>
                  <BoxImageWrapper>
                    <Img
                      fluid={props.gridimage2.childImageSharp.fluid}
                      alt="Schwingtorantrieb LEVO"
                    />
                  </BoxImageWrapper>
                </Box>
              </UpperBoxWrapper>
            </Margins>
          </BoxComponent>
        </Wrapper>
      </Layout>
    )}
  />
)

export default SingleProductPage
